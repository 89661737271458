import { createRouter, createWebHistory } from 'vue-router';
import veuxStore from '../store/store.js';
import { DynamicPage } from '@/layouts';
import {
	Home,
	Login,
	Shop,
	Search,
	Account,
	Carts,
	Checkout,
	Wishlist,
	ProductPlanner,
	EditProductPlanner,
	FlowerLibrary,
	ProductPlannerList,
	HardGoods,
	DutchDirectBoxlots,
	MassMarkets,
	Quotelist,
	Jobs,
	// JobDetails,
	// ApplyJobs,
	SPLogin,
	ESLogin,
	ChangePassword,
	ForgotPassword,
	ResetPassword,
	OrderDetails,
	PendingOrderDetails,
	CreditDetails,
	QuoteDetails,
	PplQuoteDetails,
	InvoiceHistory,
	CreditHistory,
	Specials,
	Blog,
	BlogPost,
	ShoppingLists,
	ShoppingList,
	PetalsRewards,
	PetalsEducation,
	PetalRewardsHistory,
	OrderThankYou
} from '@/pages';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			guest: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Login',
					text: 'Login',
				},
			],
		},
	},
	{
		path: '/customer-login/:customer_code',
		name: 'SPLogin',
		component: SPLogin,
	},
	{
		path: '/e-sales-customer-login/:customer_code',
		name: 'ESLogin',
		component: ESLogin,
	},
	{
		path: '/shop',
		name: 'Shop',
		component: Shop,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Shop',
					text: 'Shop',
				},
			],
		},
	},
	{
		path: '/hardgoods',
		name: 'HardGood',
		component: HardGoods,
		meta: {
			text: 'Hardgoods',
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'HardGood',
					text: 'Hardgoods',
				},
			],
		},
	},
	{
		path: '/dutch-direct-boxlots',
		name: 'DutchDirectBoxlot',
		component: DutchDirectBoxlots,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'DutchDirectBoxlot',
					text: 'Dutch Direct Boxlots',
				},
			],
		},
	},
	{
		path: '/flower-library',
		name: 'FlowerLibrary',
		component: FlowerLibrary,
		meta: {
			text: 'Flower Library',
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'FlowerLibrary',
					text: 'Flower Library',
				},
			],
		},
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
			],
		},
	},
	{
		path: '/change-password',
		name: 'Change-password',
		component: ChangePassword,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Change-password',
					text: 'Change Password',
				},
			],
		},
	},
	{
		path: '/forgot-password',
		name: 'Forgot-password',
		component: ForgotPassword,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Forgot-password',
					text: 'Forgot Password',
				},
			],
		},
	},
	{
		path: '/reset-password/:token',
		name: 'reset-password',
		component: ResetPassword,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'reset-password',
					text: 'Reset Password',
				},
			],
		},
	},
	{
		path: '/search',
		name: 'Search',
		component: Search,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Search',
					text: 'Search',
				},
			],
		},
	},
	{
		path: '/shopping-cart',
		name: 'Carts',
		component: Carts,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Carts',
					text: 'Shopping Cart',
				},
			],
		},
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: Checkout,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Carts',
					text: 'Shopping Cart',
				},
				{
					routeName: 'Checkout',
					text: 'Checkout',
				},
			],
		},
	},
	{
		path: '/product-planner',
		name: 'ProductPlanner',
		component: ProductPlanner,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'ProductPlanner',
					text: 'Product Planner',
				},
			],
		},
	},
	{
		path: '/product-planner-list',
		name: 'ProductPlannerList',
		component: ProductPlannerList,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'ProductPlannerList',
					text: 'Product Planner List',
				},
			],
		},
	},
	{
		path: '/edit-product-planner/:id',
		name: 'EditProductPlanner',
		component: EditProductPlanner,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'ProductPlanner',
					text: 'Product Planner',
				},
				{
					routeName: 'EditProductPlanner',
					text: 'Edit',
				},
			],
		},
	},
	{
		path: '/wishlist/:name',
		name: 'Wishlist',
		component: Wishlist,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'Wishlist',
					text: 'Wishlist',
				},
			],
		},
	},
	{
		path: '/quote-lists',
		name: 'quotelist',
		component: Quotelist,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'quotelist',
					text: 'Quote Lists',
				},
			],
		},
	},
	// {
	// 	path: '/careers/:id',
	// 	name: 'jobDetails',
	// 	component: JobDetails,
	// 	meta: {
	// 		breadcrumb: [
	// 			{
	// 				routeName: 'Home',
	// 				text: '<i class="fa-solid fa-house-chimney"></i>',
	// 			},
	// 			{
	// 				routeName: 'jobs',
	// 				text: 'Careers',
	// 			},
	// 			{
	// 				routeName: 'jobDetails',
	// 				text: '',
	// 			},
	// 		],
	// 	},
	// },
	{
		path: '/careers',
		name: 'jobs',
		component: Jobs,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'jobs',
					text: 'Careers',
				},
			],
		},
	},
	// {
	// 	path: '/apply-jobs/:jobId?',
	// 	name: 'apply-jobs',
	// 	component: ApplyJobs,
	// 	meta: {
	// 		breadcrumb: [
	// 			{
	// 				routeName: 'Home',
	// 				text: '<i class="fa-solid fa-house-chimney"></i>',
	// 			},
	// 			{
	// 				routeName: 'jobs',
	// 				text: 'Careers',
	// 			},
	// 		],
	// 	},
	// },
	{
		path: '/account/order-details/:order_id?',
		name: 'orderDetails',
		component: OrderDetails,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'orderDetails',
					text: 'Order Details',
				},
			],
		},
	},
	{
		path: '/account/credit-details/:credit_order_number?/:credit_date?',
		name: 'creditDetails',
		component: CreditDetails,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'creditDetails',
					text: 'Credit Details',
				},
			],
		},
	},
	{
		path: '/account/quote-details/:quote_id',
		name: 'quoteDetails',
		component: QuoteDetails,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'quoteDetails',
					text: 'Quote Details',
				},
			],
		},
	},
	{
		path: '/account/product-planner-quote-details/:ppl_quote_id',
		name: 'pplQuoteDetails',
		component: PplQuoteDetails,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'pplQuoteDetails',
					text: 'Product Planner Quote Details',
				},
			],
		},
	},
	{
		path: '/account/invoice-history',
		name: 'invoiceHistory',
		component: InvoiceHistory,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'invoiceHistory',
					text: 'Invoice History',
				},
			],
		},
	},
	{
		path: '/account/credit-history',
		name: 'creditHistory',
		component: CreditHistory,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'creditHistory',
					text: 'Credit History',
				},
			],
		},
	},
	{
		path: '/specials',
		name: 'Specials',
		component: Specials,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Specials',
					text: 'Specials',
				},
			],
		},
	},
	{
		path: '/luxe-blooms-grocery',
		name: 'massMarkets',
		component: MassMarkets,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'massMarkets',
					text: 'Luxe Bloom Grocery',
				},
			],
		},
	},
	{
		path: '/account/pending-order-details/:pending_order_id/:truck_date?',
		name: 'pendingOrderDetails',
		component: PendingOrderDetails,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'pendingOrderDetails',
					text: 'Pending Order Details',
				},
			],
		},
	},
	{
		path: '/blog',
		name: 'Blog',
		component: Blog,

		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Blog',
					text: 'Blog',
				},
			],
		},
	},
	{
		path: '/blog/:blog_post_slug',
		name: 'blogPost',
		component: BlogPost,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Blog',
					text: 'Blog',
				},
				{
					routeName: 'blogPost',
					text: '',
				},
			],
		},
	},
	{
		path: '/special-offers',
		name: 'shoppingLists',
		component: ShoppingLists,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'shoppingLists',
					text: 'Special Offers',
				},
			],
		},
	},
	{
		path: '/special-offers/:pageSlug',
		name: 'shoppingList',
		component: ShoppingList,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'shoppingLists',
					text: 'Special Offers',
				},
				{
					routeName: 'shoppingList',
					text: '',
				},
			],
		},
	},
	{
		path: '/petals-rewards',
		name: 'petalsRewards',
		alias: '/petals-rewards/:slug',
		component: PetalsRewards,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'petalsRewards',
					text: 'Petals Rewards',
				},
			],
		},
	},
	{
		path: '/petals-education',
		name: 'petalsEducation',
		alias: '/petals-education/:slug',
		component: PetalsEducation,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'petalsEducation',
					text: 'Exclusive Petals Rewards',
				},
			],
		},
	},
	{
		path: '/account/petals-rewards-history',
		name: 'petalsRewardsHistory',
		component: PetalRewardsHistory,
		meta: {
			requiresAuth: true,
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'Account',
					text: 'Account',
				},
				{
					routeName: 'petalsRewardsHistory',
					text: 'Petals Rewards History',
				},
			],
		},
	},
	{
		path: '/order-thank-you',
		name: 'orderThankYou',
		component: OrderThankYou,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'orderThankYou',
					text: 'Order Thank You',
				},
			],
		},
	},
	{
		path: '/:pageSlug',
		name: 'DynamicPage',
		alias: '/:pageSlug/:pageSlug2',
		component: DynamicPage,
		meta: {
			breadcrumb: [
				{
					routeName: 'Home',
					text: '<i class="fa-solid fa-house-chimney"></i>',
				},
				{
					routeName: 'DynamicPage',
					text: '',
				},
			],
		},
	},
	// {
	// 	path: '/:catchAll(.*)',
	// 	redirect: '/',
	// },
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			const position = {};
			if (to.hash) {
				position.selector = to.hash;
				//alert('okd')
				//console.log(to.hash, document.querySelector(to.hash))
				if (document.querySelector(to.hash)) {
					return position;
				}
			}
		}

		// return { left: 0, top: 0 };
	},
});

/* router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (localStorage.getItem('jwt') == null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath },
			});
		} else {
			let user = JSON.parse(localStorage.getItem('user'));
			if (to.matched.some((record) => record.meta.is_admin)) {
				if (user.is_admin == 1) {
					next();
				} else {
					next({ name: 'userboard' });
				}
			} else {
				next();
			}
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		if (localStorage.getItem('jwt') == null) {
			next();
		} else {
			next({ name: 'userboard' });
		}
	} else {
		next();
	}
}); */
router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!veuxStore.getters.isLoggedIn) {
			next({ name: 'Login' });
		} else {
			next();
		}
	} else if (to.matched.some((record) => record.meta.guest)) {
		if (!veuxStore.getters.isLoggedIn) {
			next();
		} else {
			next({ name: 'Home' });
		}
	} else {
		next();
	}
});

export default router;
